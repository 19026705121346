<template>
  <section class="app-container">
    <Filter @search="handleSearch" @refresh-table="handleResetTable"></Filter>
    <MatchList ref="matchListRef"></MatchList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import MatchList from "./components/MatchList";
export default defineComponent({
  name: "consultantMatchingList",
  components: {
    MatchList,
    Filter,
  },
  setup() {
    const matchListRef = ref();
    const handleSearch = (tab, searchParams) => {
      matchListRef.value.searchClick(tab, searchParams);
    };
    const handleResetTable = () => {
      matchListRef.value.doSearch();
    };
    return {
      matchListRef,
      handleSearch,
      handleResetTable,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  .bottom-content {
    height: calc(100% - 88px);
    .list-box {
      border-right: 3px solid rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
