<template>
  <section class="top-filter">
    <a-form
      ref="formRef"
      class="filter-form"
      layout="inline"
      :model="formState"
    >
      <div class="left">
        <a-tabs
          v-model:activeKey="activeTab"
          defaultActiveKey="1"
          class="left"
          @change="doSearch"
        >
          <a-tab-pane key="1" tab="处理中 In Progress"></a-tab-pane>
          <a-tab-pane key="2" tab="已完成 Completed"></a-tab-pane>
          <a-tab-pane key="3" tab="已过期 Expired"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="right">
        <!--        <a-form-item name="condition">-->
        <!--          <a-input-search-->
        <!--            v-model:value="formState.condition"-->
        <!--            placeholder="请输入项目名称/顾问姓名"-->
        <!--            enter-button-->
        <!--            @search="doSearch"-->
        <!--            allowClear-->
        <!--            autocomplete="off"-->
        <!--          />-->
        <!--        </a-form-item>-->
      </div>
    </a-form>
  </section>
</template>
<script>
import {defineComponent, reactive, ref, onMounted, onActivated, watch} from "vue";
import { useStore } from "vuex";
import {useRoute} from "vue-router";
import {useGlobalPropertyHook} from "@/hooks/common";

export default defineComponent({
  name: "Filter",
  setup(props, ctx) {
    const formRef = ref();
    const store = useStore();
    const route = useRoute();
    const { $EventBus } = useGlobalPropertyHook();
    const formState = reactive({
      condition: "",
      type: "person",
    });
    const activeTab = ref("1");
    const doSearch = () => {
      ctx.emit("search", activeTab.value, formState);
    };
    watch(
        () => route.query.id,
        () => {
          $EventBus.on("getActiveTab", (getActiveTab) => {
            activeTab.value = getActiveTab;
          });
        }
    )

    onMounted(() => {
      doSearch();
    });
    onActivated(() => {
      if(route.query.id) {
        $EventBus.on("getActiveTab", (getActiveTab) => {
          activeTab.value = getActiveTab;
        });
      }
      ctx.emit("refresh-table");
    });

    return {
      store,
      formRef,
      formState,
      activeTab,
      doSearch,
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/newTitleTab.less";
::v-deep {
  .ant-tabs-bar {
    border: none !important;
    margin-bottom: 1px !important;
  }
}

.top-filter {
  .title {
    font-weight: bold;
  }
}
</style>
