<template>
  <section class="match-list">
    <a-list
      size="small"
      bordered
      :data-source="matchList"
      :pagination="matchList.length > 0 ? pagination : false"
      :class="isTodoStatus ? '' : 'match-list-content'"
    >
      <template #renderItem="{ item, index }">
        <a-list-item class="list-item ant-list-item-no-flex" @click.stop="">
          <a-row class="padding-container">
            <a-col :span="8" style="padding-right: 3vw">
              <p
                class="first-line match-name"
              >
<!--                {{ item.demandName }}-->
<!--                <a-tag color="blue" v-show="item.completeChannel">-->
<!--                  {{ item.completeChannel }}-->
<!--                </a-tag>-->
                <Tooltip :title="item.demandType === '2' ? item.pipelineDetailVO.opportunityName : item.demandName">
                  <span class="match-name-text" @click="checkMatchDetails(index, item)">{{ item.demandType === '2' ?
                      item.pipelineDetailVO.opportunityName : item.demandName }}</span>
                </Tooltip>
                <a-tag color="blue" v-show="item.completeChannel" class="completetag">
                  {{ item.completeChannel }}
                </a-tag>
                <a-tag color="yellow" v-show="item.type === '1'" class="completetag tagColor2">
                  自定义
                </a-tag>
                <a-tag color="green" v-show="item.type === '2'" class="completetag tagColor3">
                  PPL
                </a-tag>
              </p>
              <div v-if="item.demandType === '2'">
                <ul class="key-msg-list sec-line">
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="CST">
                      <img src="@/assets/images/customize-ind.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.cst>
                      <div class="key-msg-span" style="max-width: 7vw"><span class="key-msg-span">{{ item.pipelineDetailVO.cst }}</span></div>
                    </Tooltip>
                  </li>
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="Offering">
                      <img src="@/assets/images/pip-offering.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.offering>
                      <div class="key-msg-span" style="max-width: 7vw"><span class="key-msg-span">{{ item.pipelineDetailVO.offering }}</span></div>
                    </Tooltip>
                  </li>
                  <li class="key-msg-item" style="max-width: 33%">
                    <Tooltip title="SPOC">
                      <img src="@/assets/images/customize-csm.png" class="icon img" />
                    </Tooltip>
                    <Tooltip :title=item.pipelineDetailVO.spoc>
                      <div class="key-msg-span" style="max-width: 7vw"><span>{{ item.pipelineDetailVO.spoc }}</span></div>
                    </Tooltip>
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul class="key-msg-list sec-line">
                  <li class="key-msg-item">
                    <Tooltip title="人员级别">
                      <img src="@/assets/iconfont/level.png" class="icon img" />
                    </Tooltip>
                    {{ item.staffGrade }}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="预计用人时长">
                      <ClockCircleOutlined class="icon" />
                    </Tooltip>
                    {{ item.type === '1'&&item.useStaffLength?item.useStaffLength+'个月' : item.useStaffLength}}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="到岗时间">
                      <img src="@/assets/iconfont/ScheduleOndutytime.png" class="icon img" />
                    </Tooltip>
                    {{ item.expectArrivalTime }}
                  </li>
                  <li class="key-msg-item">
                    <Tooltip title="工作地点">
                      <EnvironmentOutlined class="icon" />
                    </Tooltip>
                    {{ item.demandWorkLocation }}
                  </li>
                </ul>
              </div>
            <!-- 1042-需求匹配增加需求编号
              <p class="key-msg-item">
                需求编号：{{ item.demandNumber }}
              </p>
            -->
            </a-col>
            <a-col :span="7">
              <p class="cName" @click="checkMatchDetails(index,item)">
                {{ item.fullName }}
                <span class="first-line consultant-name">
                  {{ item.job }}
                </span>
              </p>
              <ul class="key-msg-list sec-line">
                <li class="key-msg-item">
                  <TeamOutlined class="icon" />
                  {{ item.psGroup }}
                </li>
              </ul>
            </a-col>
            <a-col :span="9" class="match-status">
              <a-steps v-show="item.preStatus !== 'pmUnmatch' && activeTab !== '0'" progress-dot
                       :current="getStepCurrent(item.preStatus)"
                       :class="activeTab === '3' ? (item.preStatus === 'invalid' ? 'astep1' : 'steps3') : activeTab === '2' ? 'steps2' : 'steps'">
                <a-step title="预匹配完成">
                  <template #description>
                    <Tooltip :title="'RM驳回：' + item.rejectReason">
                      <ExclamationCircleOutlined class="reject-icon" v-auth="['system:matching:rejectReason']"
                                                 v-show="item.rejectReason && activeTab !== '3'" @click.stop="" />
                    </Tooltip>
                    <span>{{ item.preMatchDate }}</span>
                  </template>
                </a-step>
                <a-step title="PM筛选简历">
                  <template #description>
                      <span v-if="activeTab === '3' && item.rejectReason && item.preStatus === 'pmCVReject'"
                            style="color: #DC5E4F; cursor: pointer;">
                        PM拒绝
                      </span>
                      <span v-if="activeTab === '3' && item.revertComments && item.preStatus === 'rmProposeToProject'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM撤回
                      </span>
                    <span>{{ item.filterCvDate }}</span>
                  </template>
                </a-step>
                <a-step title="PM填写调令">
                  <template #description>
                    <Tooltip :title="'驳回原因：' + item.rejectReason">
                      <span v-if="activeTab === '3' && item.rejectReason && item.preStatus === 'pmInterviewFail'"
                            style="color: #DC5E4F; cursor: pointer;">
                        PM驳回
                      </span>
                    </Tooltip>
                    <span>{{ item.fillAssignmentDate }}</span>
                    <Tooltip :title="'撤回原因：' + item.revertComments">
                      <span v-if="activeTab === '3' && item.revertComments && item.preStatus === 'rmPmCVInterview'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM撤回
                      </span>
                    </Tooltip>
                  </template>
                </a-step>
                <a-step title="RM确认调令">
                  <template #description>
                    <Tooltip :title="'驳回原因：' + item.rejectReason">
                      <span v-if="activeTab === '3' && item.rejectReason && item.preStatus === 'rmPmSoftBook'"
                            style="color: #DC5E4F; cursor: pointer;">
                        RM驳回
                      </span>
                    </Tooltip>
                    <span>{{ item.confirmAssignmentDate }}</span>
                  </template>
                </a-step>
                <a-step title="匹配完成">
                  <template #description>
                    <span>{{ item.confirmAssignmentDate }}</span>
                  </template>
                </a-step>
              </a-steps>
              <span v-show="item.preStatus === 'pmUnmatch'">
                本需求PM已有选定人员
              </span>
            </a-col>
            <a-col :offset="1" :span="5" class="match-btn">
              <a-space class="buttons">
                <!--顾问-->
                <a-button
                  type="primary"
                  @click="openDemandModal(item)"
                  v-auth="['system:prematching:demand']"
                >
                  查看需求信息
                </a-button>
              </a-space>
            </a-col>
          </a-row>
        </a-list-item>
      </template>
    </a-list>
  </section>
  <mate-info
    v-if="showMateInfo"
    :showMateInfo="showMateInfo"
    :empDetail="empDetail"
    @closeMataInfoModal="closeMataInfoModal"
  />
  <demand-info ref="demandInfoRef" title="查看需求信息" :isHaveAdd="false" />
  <CustomizeInfo ref="CustomizeInfoRef"
                 :nowStep="0"
                 type="List"
                 title="匹配详情"
  />
  <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo"
                 @close-modal="closePipelineModal" :nowStep="0" title="匹配详情"
  />
</template>
<script>
import {computed, defineComponent, reactive, ref, watch} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  EnvironmentOutlined,
  TeamOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons-vue";
import MateInfo from "@/views/home/ResourceManagement/Match/matchingList/components/MateInfo.vue";
import DemandInfo from "@/views/home/ResourceManagement/Match/newMatch/components/DemandInfo";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useStore } from "vuex";
import CustomizeInfo from "@/views/home/ResourceManagement/Match/newMatch/components/CustomizeInfo.vue";
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
import moment from "moment/moment";

export default defineComponent({
  name: "MatchList",
  components: {
    DemandInfo,
    EnvironmentOutlined,
    ClockCircleOutlined,
    TeamOutlined,
    MateInfo,
    CustomizeInfo,
    PipelineModal,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const route = useRoute();
    const store = useStore();

    const isTodoStatus = computed(() => store.state.app.isTodoStatus);
    const storePerms = computed(() => store.state.app.perms);

    const matchList = ref([]);
    const activeTab = ref('');
    const searchCondition = ref({});
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        doSearch();
        // getMatchListData(queryCache);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        doSearch();
        // getMatchListData(queryCache);
      },
    });
    const showMateInfo = ref(false);
    const empDetail = reactive({});

    let CustomizeInfoRef = ref()

    //pipeline需求信息弹窗
    const pipelineVisible = ref(false)
    let optType = 'match'
    const pipelineInfo = ref({})
    const closePipelineModal = () => {
      pipelineInfo.value = {}
      pipelineVisible.value = false
    }

    // 查看项目详情
    const checkMatchDetails = (index,item) => {
      if (item.type == 0) {
        showMateInfo.value = true;
      } else if (item.type == 1) {
        CustomizeInfoRef.value.showModal(item)
      } else {
        pipelineInfo.value = Object.assign({}, item, item.pipelineDetailVO)
        console.log(1,pipelineInfo.value)
        pipelineVisible.value = true
      }
      Object.assign(empDetail, matchList.value[index]);
    };

    const closeMataInfoModal = () => {
      showMateInfo.value = false;
    };
    const doSearch = () => {
      const params = {
        status: activeTab.value,
        demandNumber: searchCondition.value.demandNumber,
        condition: searchCondition.value.condition,
        type: searchCondition.value.type,
        completeChannel: searchCondition.value.completeChannel,
        pageIndex: pagination.value.current,
        pageSize: pagination.value.pageSize,
      };
      if (isTodoStatus.value) {
        params.preMatchingId = route.query.preMatchingId;
      }
      if(noticePreMatchingId.value) {
        if(getActiveTab.value === '') {
          params.status = ''
          params.preMatchingId = noticePreMatchingId.value
        }
      }
      $api.searchMatchByCondition(params).then((res) => {
        console.log("6666",activeTab.value)
        matchList.value = res.data.resource;
        matchList.value = matchList.value.map(item => {
          return {
            ...item,
            arveAssumptionStartDate: moment(item.arveAssumptionStartDate).format("YYYY-MM-DD"),
            consultantArveAssumptionStartDate: moment(item.arveAssumptionStartDate).format("YYYY-MM-DD"),
            consultantArveAssumption: item.arveAssumption,
            consultantArveAssumptionHc: item.arveAssumptionHc,
            pmInfo: item.pmInfo !== 'null' ? item.pmInfo : '暂无PM',
          }
        })
        pagination.value.total = res.data.total;
        if(noticePreMatchingId.value) {
          handlegetActiveTab()
        }
      });
    };

    const searchClick = (tab, searchParams) => {
      activeTab.value = tab;
      searchCondition.value = searchParams;
      pagination.value.current = 1;
      doSearch();
    };
    const getActiveTab = ref('')
    const noticePreMatchingId = computed(()=> route.query.id)
    const handlegetActiveTab = () => {
      if(matchList.value.length > 0) {
        let inProgress = ["proposeToProject", "pmCVInterview", "pmSoftBook", "rmReject", "pmUnmatch"]
        let Completed = ["rmHardBook"]
        let Invalid = ["pmCVReject", "invalid", "pmInterviewFail"]
        if (inProgress.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = '1'
        } else if (Completed.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = '2'
        } else if (Invalid.includes(matchList.value[0].preStatus)) {
          getActiveTab.value = '3'
        }
        activeTab.value = getActiveTab.value
        $EventBus.emit("getActiveTab", getActiveTab.value);
      }
    }
    const { $EventBus } = useGlobalPropertyHook();

    const demandInfoRef = ref();
    const openDemandModal = (row) => {
      $api.getDemandInfo({ id: row.demandId }).then((res) => {
        demandInfoRef.value.showModal(res.data);
      });
    };

    const getStepCurrent = (preStatus) => {
      // console.log(preStatus,'什么是preStatus')
      // switch (preStatus) {
      //   case "pmCVInterview":
      //   case "rmReject":
      //     return 1;
      //   case "pmSoftBook":
      //     return 2;
      //   case "rmHardBook":
      //     return 4;
      //   default:
      //     return 0;
      // }
      switch (preStatus) {
        case "proposeToProject":
        case "pmCVReject":
        case "rmProposeToProject":
          return 1;
        case "rmReject":
        case "pmCVInterview":
        case "pmTransferOrderToBeFilledOut":
        case "pmInterviewFail":
        case "rmPmCVInterview":
          return 2;
        case "pmSoftBook":
        case "rmPmSoftBook":
          return 3;
        case "rmHardBook":
          return 4;
        default:
          return 0;
      }
    };

    onBeforeRouteLeave(() => {
      if (isTodoStatus.value) {
        store.commit("SET_IS_TODO_STATUS", false);
      }
      getActiveTab.value = ''
    });
    watch(
        () => route.query.id,
        () => {
          getActiveTab.value = ''
          noticePreMatchingId.value = route.query.id
          doSearch()
        }
    )

    return {
      searchCondition,
      matchList,
      pagination,
      checkMatchDetails,
      empDetail,
      closeMataInfoModal,
      showMateInfo,
      searchClick,
      doSearch,
      openDemandModal,
      demandInfoRef,
      getStepCurrent,
      storePerms,
      noticePreMatchingId,
      handlegetActiveTab,
      getActiveTab,
      isTodoStatus,
      CustomizeInfoRef,
      pipelineVisible,
      optType,
      pipelineInfo,
      closePipelineModal,
      activeTab
    };
  },
});
</script>
<style scoped lang="less">
@import "~@/style/aModal.less";
// 覆盖ant-design样式
//::v-deep .ant-list-items {
//  height: calc(100vh - 3.90625vw - 4.58333vw - 70px);
//  overflow: auto;
//
//  &::-webkit-scrollbar {
//    width: 10px;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    width: 10px;
//    background: #d0e8ff;
//    border: 1px solid #d0e8ff;
//    border-radius: 5px;
//  }
//}

:deep(.ant-list-empty-text) {
  height: calc(100vh - 3.90625vw - 4.58333vw - 40px - 80px);
  width: 100%;
  display: table;
}

:deep(.ant-empty) {
  display: table-cell;
  vertical-align: middle;
}

.match-list {
  flex: 1;

  .match-list-content {
    :deep(.ant-spin-nested-loading) {
      height: calc(100vh - 13vw);
      overflow-y: auto;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .list-item {
    padding: 0;
  }

  .padding-container {
    margin: 0 1vw 0 1.25vw;
    padding: 1vw 0 0;
    position: relative;

    .steps {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #77CAED;
        top: 0;
      }
    }

    .steps2 {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #3182CE;
        top: 0;
      }
    }
    //红叉❌
    .steps3 {
      margin-bottom: 12px;

      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #DC5E4F;
        top: 0;

        &::after {
          content: 'x';
          top: -0.22vw;
          left: -1.28vw;
        }
      }
    }
    .astep1 {
      :deep(.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
        background: #3182CE;
        top: 0;

        &::after {
          content: '';
          top: -0.22vw;
          left: -1.28vw;
        }
      }
    }
    .buttons {
      padding-bottom: 24px;
    }

    //.buttons {
    //  //width: 100px;
    //  position: absolute;
    //  top: 30%;
    //  left: 10%;
    //  transform: translate(0, -50%);
    //}
  }

  :deep(.ant-list-bordered) {
    border: 0px;
  }

  .first-line {
    font-size: 18px;
    color: #1890ff;
    margin-bottom: 12px;
  }

  .match-name {
    cursor: pointer;
    position: relative;
    display: flex;

    .match-name-text {
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: .9375vw;
      color: #4d4d4d;
      font-weight: 600;
      border-bottom: 1px solid #4d4d4d;
      max-width: 25vw;
    }

    .completetag {
      padding-top: 0.15vw;
      font-size: 0.73vw;
    }

    .tagColor2 {
      color: #FDCD46;
      background: #FFFCB8;
      border-color: #FFFCB8;
    }

    .tagColor3 {
      color: #FFFFFF;
      background: #58B99E;
      border-color: #58B99E;
    }

    .reject-icon {
      position: absolute;
      top: 5px;
      padding-left: 10px;
      left: -35px;
      color: #ff4d4f;
      cursor: pointer;
    }

    .ant-tag {
      margin-left: 0.6vw;
      border-radius: 1vw;
      line-height: inherit;
      max-height: 1.7vw;
    }

    :deep(.ant-tag-blue) {
      color: #3182CE;
      background: #E9F6FE;
      border-color: #E9F6FE;
    }
  }

  .sec-line {
    color: #505050;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .more-info {
    padding: 0 32px 24px;
    display: flex;
    align-items: center;
    width: 200px;
    cursor: pointer;

    .more-info-icon {
      color: #1890ff;
      margin-right: 7px;
    }
  }

  .suplement {
    line-height: 32px;
    background: #d0e8ff;
    padding: 0 32px;

    &.un-active {
      max-height: 0;
      overflow: hidden;
    }

    &.active {
      max-height: 1000px;
      transition: all 2s linear;
    }
  }

  .key-msg-list {
    display: flex;
    align-items: center;

    .key-msg-item {
      display: flex;
      align-items: center;

      .key-msg-span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        content: "";
        display: inline-block;
        height: 17px;
        width: 0.05vw;
        background-color: #359eff;
        margin: 0 10px;
      }

      &:last-child {
        border: none;

        &::after {
          width: 0;
        }
      }

      .img {
        width: 1vw;
        height: 1vw;
        cursor: pointer;
        margin-bottom: 0.1vw;
      }

      .levelimg {
        width: 0.8vw;
        height: 0.8vw;
        cursor: pointer;
      }
    }
  }

  .add-btn {
    width: 35px;
    height: 35px;
    border: 2px solid #1890ff;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1890ff;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .match-status {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding-bottom: 24px;
    }
  }

  .match-btn {
    position: absolute;
    bottom: -10px;
    right: 0;
  }

  :deep(.ant-pagination) {
    justify-content: flex-end;
  }
}

.cName {
  cursor: pointer;
}
</style>
